import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardSubtitle,
  Form,
  FormGroup,
  Label,
  Button,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { format, parseISO } from 'date-fns'
import { Fragment } from "react";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Select from "react-select";
import axios from "axios";
import config from "../../config.json";
import { debounce } from "../../helpers/utils";
import { MDBDataTable } from "mdbreact";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

class OrderEdit extends Component {
  constructor() {
    super();
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      selectedFiles: [],
      categories: [],
      errorMsg: "",
      newProductName: "",
      s3FileName: "",
      calcDeliveryModal: false,
      placeDeliveryModal: false,
      fields: {},
      orderId: "",
      storeId: "",
      delivery_value: "",
      delivery_description: "",
      driverKey: "",
      driverId: "",
      userRole: "",
      selectedPay: null,
      optionPay: [],
      selectedAddressType: null,
      optionAddressType: [],
      stay: false,
      address_info: [],
      toAddressLine1Auto: [],
      currentDateTime: '',
      preparationTimeInTime: '',
      orderInfo:{},
    };
  }

  componentDidMount() {
    let driverKey = sessionStorage.getItem("secretKey");
    let driverId = sessionStorage.getItem("userId");
    var userRoleInSession = sessionStorage.getItem("userRole");
    if (this.props.location?.state !== undefined) {
      sessionStorage.setItem(
        "user",
        JSON.stringify(this.props.location?.state)
      );

      const user = sessionStorage?.getItem("user");

      const orderId = user !== undefined ? JSON.parse(user) : {};
      this.setState.bind(this)({
        driverKey,
        driverId,
        orderId,
      });

      this.getOrderInfo(orderId);
      this.arrayToList();
    }
    this.setState({
      driverKey,
      driverId,
      userRole: userRoleInSession,
      selectedAddressType: { value: 'HOME', label: 'Home' },
      selectedPay: { value: 'PAID', label: 'Pre-paid' }
    });
    const { match, location, history } = this.props;
  }

  arrayToList() {
    let optionPay = [];
    let optionAddressType = [];
    
    optionPay.push({ value: 'PAID', label: 'Pre-paid' });
    optionPay.push({ value: 'NP_CASH', label: 'Cash' });
    optionPay.push({ value: 'NP_DEBITCARD', label: 'Debit/Credit' });

    optionAddressType.push({ value: 'HOME', label: 'Home' });
    optionAddressType.push({ value: 'WORK', label: 'Work' });
    optionAddressType.push({ value: 'FRIEND', label: 'Friend' });
    optionAddressType.push({ value: 'Other', label: 'Other' });

    this.setState({
      optionPay,
      optionAddressType
    });
  }

  //Select

  handleSelectPay = (selectedPay) => {
    this.setState({ selectedPay });
  };

  handleSelectAddressType = (selectedAddressType) => {
    this.setState({ selectedAddressType });
  };

  handleStay = async (stay) => {
    this.setState({ stay });
  };

  updatePreparationTimeInTimeValue(evt) {
    this.setState({preparationTimeInTime:evt.target.value})
  };

  handleSubmit = async (event, error, values) => {
    this.setState({ error, values });

    if (typeof error?.length !== "undefined" && error?.length === 0) {
      if (values) {
        try {
          let driverKey = sessionStorage.getItem("secretKey");
          let driverId = sessionStorage.getItem("userId");
          if (
            this.state.userRole === "ADMIN" ||
            this.state.userRole === "SUPER_ADMIN"
          ) {
            values.storeId = this.state.storeId;
          }
          values.toAddressType = this.state.selectedAddressType.value;
          if (!(this.state.toAddressLine1Auto && this.state.toAddressLine1Auto[0] != '')) {
            values.toAddressLine1 = 'GET INFO FROM STORE';
          } else {
            values.toAddressLine1 = 'GET INFO FROM STORE';
            if (this.state.toAddressLine1Auto[0] && this.state.toAddressLine1Auto[0].includes(',')) {
              values.toAddressLine1 = this.state.toAddressLine1Auto[0].split(',')[0];
            } else if (this.state.toAddressLine1Auto[0]) {
              values.toAddressLine1 = this.state.toAddressLine1Auto[0];
            }
          }
          values.toPay = this.state.selectedPay.value;
          if (values.toPostalCode === 'NA') {
            values.toPostalCode = '';
          }
          if (values.toCity === 'NA') {
            values.toCity = '';
          }
          if (values.toProvince === 'NA') {
            values.toProvince = '';
          }
          if (values.toCountry === 'NA') {
            values.toCountry = '';
          }
          await this.calcDelivery(values, driverId, driverKey);
          this.setState({
            fields: values,
          });
        } catch (error) {
            console.error("Error:", error);
        }
      }
    }
  };

  calcDelivery = async (Object, driverId, driverKey) => {
    fetch(
      config.DELIVERY_API + "updateCalcDeliveryCharges/" + driverId + "/" + driverKey + "/" + this.state.orderId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Object),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          this.setState({ errorMsg: data.message || data.error });
        } else {
          console.log(data);
          this.setState({
            delivery_description: data.description,
            delivery_value: data.value,
          });
          // this.form && this.form.reset();
          this.calcDeliveryModal();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ errorMsg: "Fail to load" });
      });
  };

  backToDispatch = () => {
    this.props.history.push("ecommerce-orders");
  };

  getOrderInfo = async (orderId) => {
    let driverKey = sessionStorage.getItem("secretKey");
    let driverId = sessionStorage.getItem("userId");
              
    fetch(
      config.DELIVERY_API + "getOrderInfo/" + orderId + "/" + driverId + "/" + driverKey,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          this.setState({ errorMsg: data.message || data.error });
        } else {
          console.log(data);
          if (data) {
            
            try {
              var values = [];
              if (data.deliveryAddress.addressType == 'HOME') {
                this.setState({selectedAddressType: { value: 'HOME', label: 'Home' }})
              }
              if (data.deliveryAddress.addressType == 'WORK') {
                this.setState({selectedAddressType: { value: 'WORK', label: 'Work' }})
              }
              if (data.deliveryAddress.addressType == 'FRIEND') {
                this.setState({selectedAddressType: { value: 'FRIEND', label: 'Friend' }})
              }
              if (data.deliveryAddress.addressType == 'Other') {
                this.setState({selectedAddressType: { value: 'Other', label: 'Other' }})
              }

              if (data.collectionType == 'PAID') {
                this.setState({selectedPay: { value: 'PAID', label: 'Pre-paid' }})
              }
              if (data.collectionType == 'NP_CASH') {
                this.setState({selectedPay: { value: 'NP_CASH', label: 'Cash' }})
              }
              if (data.collectionType == 'NP_DEBITCARD') {
                this.setState({selectedPay: { value: 'NP_DEBITCARD', label: 'Debit/Credit' }})
              }
              values.toPayAmount = data.collectionAmount;
              values.toFirstName = data.deliveryFirstName;
              values.toLastName = data.deliveryLastName;
              values.toMobileNumber = data.deliveryMobileNumber;
              //values.toAddressLine1 = data.deliveryAddress.addressLine1;
              this.setState({
                toAddressLine1Auto:[data.deliveryAddress.addressLine1]
              });
              values.toAddressLine2 = data.deliveryAddress.addressLine2;
              if (data.deliveryAddress.postalCode === 'NA') {
                values.toPostalCode = '';
              } else {
                values.toPostalCode = data.deliveryAddress.postalCode;
              }
              if (data.deliveryAddress.city === 'NA') {
                values.toCity = '';
              } else {
                values.toCity = data.deliveryAddress.city;
              }
              if (values.toProvince === 'NA') {
                values.toProvince = '';
              } else {
                values.toProvince = data.deliveryAddress.province
              }
              if (values.toCountry === 'NA') {
                values.toCountry = '';
              } else {
                values.toCountry = data.deliveryAddress.country;
              }
              values.toAdditionalInfo = data.notes;
              console.log("#####");
              console.log(values);
              this.setState({
                fields: values,
                orderInfo: data,
                storeId: data.storeId
              });
            } catch (error) {
                console.error("Error:", error);
            }
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ errorMsg: "Fail to load" });
      });
  };

  debounce = (func, timeout = 3000) => {
    let timer;
    return (...args) => {
      if (!timer) {
        func.apply(this, args);
      }
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
      }, timeout);
    };
  };

  placeDelivery = async () => {
    fetch(
      config.DELIVERY_API +
        "updateDeliveryOrder/" +
        this.state.driverId +
        "/" +
        this.state.driverKey + "/" + this.state.orderId,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state.fields),
      }
    )
      .then((response) => {
        this.setState({
          order_info:
            response.status === 200 ? "Order is successfully updated." : "",
          errorMsg:
            response.status !== 200
              ? "Service failure, Please contact support team."
              : "",
        });

        this.calcDeliveryModal();
        this.placeDeliveryModal();

        if (response.status === 200 && !this.state.stay) {
          this.props.history.push("ecommerce-orders");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ errorMsg: error });
      });
  };

  calcDeliveryModal = () => {
    this.setState((prevState) => {
      return {
        calcDeliveryModal: !prevState.calcDeliveryModal,
      };
    });
  };

  placeDeliveryModal = () => {
    this.setState((prevState) => {
      return {
        placeDeliveryModal: !prevState.placeDeliveryModal,
      };
    });
  };

  populateAddressFields = (element) => {

    if (element) {
      this.setState({
        toAddressLine1Auto:element
      });
    }
    if (element && element[0]) {
      var previousFields = this.state.fields;
      this.setState({
        fields: {
          ...previousFields,
          toAddressLine1: element[0].split(",")[0],
          toCity: element[0].split(",")[1],
          toProvince: element[0].split(",")[2],
          toPostalCode: '',
          toAddressLine2: ''
        },
      })
    }
  }

  getAddressInfoByAddressLine = async (value) => {
    if (value && value != null && value.length > 2) {
      fetch(
        `${config.DELIVERY_API}getLocationSuggestionsOnAddress/${this.state.driverId}/${this.state.driverKey}/${value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((data) => data.json())
      .then((response) => {
        let res = [];
        if (response != null) {
          res = response;
        }
        let data = [];
        if (res) {
          res.suggestions.forEach((element) => {
            data.push(element);
          });
        }

        this.setState({
          mobile_info: [],
          address_info: data
        });
      })
      .catch((error) => {
        console.error("Error:", error);
        toastr.error("Enter more than 7 digit", 'Error');
      });
    }
  };

  render() {
    const { selectedPay, optionPay, optionAddressType, selectedAddressType } = this.state;

    return (
      <React.Fragment>
        <div className="page-content" style={{ marginTop: "0px" }}>
          <Container fluid>
            {this.state.errorMsg ? (
              <Alert color="danger">
                {this.state.errorMsg === "No message available"
                  ? "Service failure, Please contact support team."
                  : this.state.errorMsg}
              </Alert>
            ) : (
              ""
            )}

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <AvForm
                      onSubmit={this.handleSubmit}
                      id="editDriverForm"
                      className="needs-validation"
                      ref={(c) => (this.form = c)}
                    >
                      
                        
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <Label className="text-primary" htmlFor="storeName">Order Info</Label>
                            <span className="font-weight-bold text-danger"> {this.state.orderInfo.id}</span>
                          </FormGroup>
                        </Col>
                        <Col>
                          <div className="text-sm-right">
                            <a
                              className={"btn btn-primary mr-4"}
                              onClick={() => this.backToDispatch()}
                              role="button"
                              href="##"
                            >
                              <i className="bx bx-task"></i> Back to dispatch
                            </a>
                          </div>
                        </Col>
                      </Row>
                      
                      <Row>
                        <Col md="8">
                          <Row>
                            <Col md="12">
                              <FormGroup>
                                <Label
                                  className="font-weight-bold text-primary"
                                  style={{ margin: 0, padding: 0 }}
                                >
                                  Delivery Info
                                </Label>
                                <hr style={{ margin: 0, padding: 0 }} />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4">
                              <FormGroup className="select2-container">
                                <Label>Address type</Label>
                                <Select
                                  value={selectedAddressType}
                                  onChange={this.handleSelectAddressType}
                                  options={optionAddressType}
                                  validate={{ required: { value: true } }}
                                  id="toAddressType"
                                  name="toAddressType"
                                  errorMessage="Enter address type"
                                />
                              </FormGroup>
                            </Col>
                          
                            <Col md="4">
                              <FormGroup>
                                <Label>First name</Label>
                                <AvField
                                  name="toFirstName"
                                  placeholder="First name"
                                  type="text"
                                  errorMessage="Enter First name"
                                  className="form-control"
                                  validate={{ required: { value: false } }}
                                  id="toFirstName"
                                  value={this.state.fields.toFirstName}
                                />
                              </FormGroup>
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <Label>Last name</Label>
                                <AvField
                                  name="toLastName"
                                  placeholder="Last name"
                                  type="text"
                                  errorMessage="Enter Last name"
                                  className="form-control"
                                  validate={{ required: { value: false } }}
                                  id="toLastName"
                                  value={this.state.fields.toLastName}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="6">
                              <FormGroup>
                                <Label>Address line 1</Label>
                                <AsyncTypeahead
                                  id="toAddressLine1Auto"
                                  labelKey="toAddressLine1Auto"
                                  onSearch={(query) => {this.getAddressInfoByAddressLine(query)}}
                                  onChange={(selected) => {this.populateAddressFields(selected)}}
                                  onFocus={() => {this.setState({toAddressLine1Auto:''})}}
                                  options={this.state.address_info}
                                  placeholder="Address line *"
                                  selected={this.state.toAddressLine1Auto}
                                />
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                  <Label>Apt / Unit number</Label>
                                  <AvField
                                    name="toAddressLine2"
                                    placeholder="Apt / Unit number"
                                    type="text"
                                    errorMessage="Enter Apt / Unit number"
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="toAddressLine2"
                                    value={this.state.fields.toAddressLine2}
                                  />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <Label>Postal code</Label>
                                <AvField
                                  name="toPostalCode"
                                  placeholder="Postal code"
                                  type="text"
                                  errorMessage="Enter postal code"
                                  className="form-control"
                                  validate={{ required: { value: false } }}
                                  id="toPostalCode"
                                  value={this.state.fields.toPostalCode}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                  <Label>City</Label>
                                  <AvField
                                    name="toCity"
                                    placeholder="City"
                                    type="text"
                                    errorMessage="Enter city"
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="toCity"
                                    value={this.state.fields.toCity}
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="4">
                                <FormGroup>
                                  <Label>Province</Label>
                                  <AvField
                                    name="toProvince"
                                    placeholder="Province"
                                    type="text"
                                    errorMessage="Enter province"
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    id="toProvince"
                                    value={this.state.fields.toProvince}
                                  />
                                </FormGroup>
                              </Col>
                          </Row>
                          <Row>
                            <Col md="4">
                              <FormGroup>
                                <Label htmlFor="toAdditionalInfo">
                                  Mobile number
                                </Label>
                                <AvField
                                  name="toMobileNumber"
                                  placeholder="Delivery mobile number"
                                  type="text"
                                  errorMessage="Enter delivery mobile number"
                                  className="form-control"
                                  validate={{ required: { value: false } }}
                                  id="toMobileNumber"
                                  value={this.state.fields.toMobileNumber}
                                />
                              </FormGroup>
                            </Col>
                          
                            <Col md="4">
                              <FormGroup className="select2-container">
                                <Label>Payment info*</Label>
                                <Select
                                  value={selectedPay}
                                  onChange={this.handleSelectPay}
                                  options={optionPay}
                                  validate={{ required: { value: true } }}
                                  id="payment"
                                  name="payment"
                                  errorMessage="Enter payment info"
                                />
                              </FormGroup>
                            </Col>
                            <Col md="4">
                              <FormGroup className="select2-container">
                                <Label>Total amount $</Label>
                                <AvField
                                  name="toPayAmount"
                                  placeholder="Total amount to be collected"
                                  type="text"
                                  errorMessage="Enter pay amount"
                                  defaultValue="0"
                                  className="form-control"
                                  validate={{ required: { value: false } }}
                                  id="toPayAmount"
                                  value={this.state.fields.toPayAmount}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label htmlFor="toAdditionalInfo">
                                  Additional info
                                </Label>
                                <AvField
                                  name="toAdditionalInfo"
                                  placeholder="e.g Please use side door, Buzz #1256"
                                  type="text"
                                  errorMessage="Enter Additional info"
                                  className="form-control"
                                  validate={{ required: { value: false } }}
                                  id="toAdditionalInfo"
                                  value={this.state.fields.toAdditionalInfo}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                                <Button
                                    color="success"
                                    type="submit"
                                    onClick={() => {
                                    this.handleStay(false);
                                    this.handleSubmit();
                                    }}
                                >
                                    Update Order
                                </Button>{" "}
                                </Col>
                            </Row>
                        </Col>
                        <Col md="4">
                          {this.state.userRole === "ADMIN" ||
                          this.state.userRole === "SUPER_ADMIN" && 
                          <>
                              <Row>
                                  <Col md="12" className="mt-2">
                                      <FormGroup>
                                      <Label
                                          className="font-weight-bold text-primary"
                                          style={{ margin: 0, padding: 0 }}
                                      >
                                          Store Info
                                      </Label>
                                      <hr style={{ margin: 0, padding: 0 }} />
                                      </FormGroup>
                                  </Col>
                              </Row>
                              <Row>
                                  <Col md="12">
                                      <span className="font-weight-bold">Store name </span><span className="font-weight-bold text-danger">{this.state.orderInfo.storeName}</span>
                                  </Col>
                                  <Col md="12">
                                      <span className="font-weight-bold">Store phone number </span><span className="font-weight-bold text-danger">{this.state.orderInfo.storeMobileNumber}</span>
                                  </Col>
                                  <Col md="12">
                                      <span className="font-weight-bold">Address </span><span className="font-weight-bold text-danger">{this.state.orderInfo.storeAddress?.addressLine1}</span>
                                  </Col>
                                  <Col md="12">
                                      <span className="font-weight-bold text-danger">{this.state.orderInfo.storeAddress?.addressLine2}</span>
                                  </Col>
                                  <Col md="12">
                                      <span className="font-weight-bold">Spl instructions </span><span className="font-weight-bold text-danger">{this.state.orderInfo.storeNotes !== null && this.state.orderInfo.storeNotes !== '' ? this.state.orderInfo.storeNotes : 'NA'}</span>
                                  </Col>
                                  <hr/>
                              </Row>
                              
                          </>
                          }
                          <Row>
                              <Col md="12" className="mt-2">
                                  <FormGroup>
                                      <Label
                                      className="font-weight-bold text-primary"
                                      style={{ margin: 0, padding: 0 }}
                                      >
                                          Driver Info
                                      </Label>
                                      <hr style={{ margin: 0, padding: 0 }} />
                                  </FormGroup>
                              </Col>
                          </Row>
                          <Row>
                              {this.state.orderInfo.deliveryStatus !== 'DELIVER_NOTASSIGN' && this.state.orderInfo.driverName && <Col md="12">
                                  <span className="font-weight-bold text-danger">{this.state.orderInfo.driverName}</span>, <span className="font-weight-bold text-danger">{this.state.orderInfo.driverPhoneNumber}</span>
                              </Col>}
                              {this.state.orderInfo.deliveryStatus !== 'DELIVER_NOTASSIGN' && this.state.orderInfo.driverName && <Col md="12">
                                <div className="table-responsive">
                                      <Table className="table table-sm table-bordered table-nowrap table-hover">
                                          <thead className="thead-dark" style={{position: "sticky", top: 0, zindex: 1}}>
                                              <tr>
                                                  <th>Status</th>
                                                  <th>Date</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              {this.state.orderInfo.orderSteps?.length > 0 &&
                                                  this.state.orderInfo.orderSteps.map((orderStep, key) => {
                                                      return (
                                                          <Fragment key={"_order_step_" + key}>
                                                              <tr>
                                                                  <td>
                                                                    <span className="font-weight-bold text-danger">{orderStep.deliveryStatus
                                                                      ? orderStep.deliveryStatus ===
                                                                          "DELIVER_ASSIGN"
                                                                          ? "Assigned"
                                                                          : orderStep.deliveryStatus ===
                                                                              "DELIVER_ACCEPT"
                                                                              ? "Accept"
                                                                              : orderStep.deliveryStatus ===
                                                                                  "DELIVER_ONTOSTORE"
                                                                                  ? "On route"
                                                                                  : orderStep.deliveryStatus ===
                                                                                      "DELIVER_REACHEDSTORE"
                                                                                      ? "Arrived"
                                                                                      : orderStep.deliveryStatus ===
                                                                                          "DELIVER_LEFTSTORE"
                                                                                          ? "Left store"
                                                                                          : orderStep.deliveryStatus ===
                                                                                              "DELIVER_ONTOCLIENT"
                                                                                              ? "Way to drop"
                                                                                              : orderStep.deliveryStatus ===
                                                                                                  "DELIVER_COMPLETED"
                                                                                                  ? "Clear"
                                                                                                  : "NA"
                                                                      : "NA"}</span>
                                                                  </td>
                                                                  <td>
                                                                      <span>{orderStep?.completeDate && format(parseISO(orderStep?.completeDate), 'dd/MM/yyyy hh:mm a')} </span>
                                                                  </td>
                                                              </tr>
                                                          </Fragment>
                                                      );
                                                  })
                                              }
                                          </tbody>
                                      </Table>
                                  </div>
                              </Col>}
                              <Col md="12">
                                  <span className="font-weight-bold">Order status </span><span className="font-weight-bold text-danger">{this.state.orderInfo.status}</span>
                              </Col>
                              <Col md="12">
                                  <span className="font-weight-bold">Order created </span><span className="font-weight-bold text-danger">{this.state.orderInfo.createDate && format(parseISO(this.state.orderInfo.createDate), 'dd/MM/yyyy hh:mm a')}</span>
                              </Col>
                              <Col md="12">
                                  <span className="font-weight-bold">Order last updated </span><span className="font-weight-bold text-danger">{this.state.orderInfo.updateDate && format(parseISO(this.state.orderInfo.updateDate), 'dd/MM/yyyy hh:mm a')}</span>
                              </Col>
                          </Row>

                          {this.state.orderInfo.items?.length > 0 &&
                            <>
                              <Row>
                                <Col md="12" className="mt-2">
                                    <FormGroup>
                                        <Label
                                        className="font-weight-bold text-primary"
                                        style={{ margin: 0, padding: 0 }}
                                        >
                                            Order Info
                                        </Label>
                                        <hr style={{ margin: 0, padding: 0 }} />
                                    </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12">
                                    <div className="table-responsive">
                                        <Table className="table table-sm table-bordered table-nowrap table-hover">
                                            <thead className="thead-dark" style={{position: "sticky", top: 0, zindex: 1}}>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Qty</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.orderInfo.items?.length > 0 &&
                                                    this.state.orderInfo.items.map((item, key) => {
                                                        return (
                                                            <Fragment key={"_order_" + key}>
                                                                <tr>
                                                                    <td>
                                                                        <span>{item?.name} </span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{item?.quantity} </span>
                                                                    </td>
                                                                    <td>
                                                                        <span>{item.price} </span>
                                                                    </td>
                                                                </tr>

                                                                {item?.menuOptions?.map((menu, mkey) => {
                                                                  return (
                                                                    <Fragment key={"_menu_order_" + mkey}>
                                                                      {menu.popupMenuOptions?.map((popupMenu, pkey) => {
                                                                        return (
                                                                          <Fragment key={"_pop_order_" + pkey}>
                                                                            <tr>
                                                                              <td>
                                                                                  <span>{" >> >> "}{popupMenu?.name} </span>
                                                                              </td>
                                                                              <td>
                                                                                  <span>{popupMenu?.quantity} </span>
                                                                              </td>
                                                                              <td>
                                                                                  <span>{popupMenu.price} </span>
                                                                              </td>
                                                                            </tr>
                                                                          </Fragment>
                                                                        )
                                                                      })}
                                                                    </Fragment>
                                                                  )
                                                                })}
                                                            </Fragment>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                              </Row>
                            </>
                          }
                        </Col>
                      </Row>
                    </AvForm>
                  </CardBody>
                </Card>

                <Modal
                  isOpen={this.state.calcDeliveryModal}
                  toggle={this.calcDeliveryModal}
                  className="modal-dialog modal-dialog-centered"
                >
                  <ModalHeader toggle={this.calcDeliveryModal}>
                    Are you sure you want to update Order ?
                  </ModalHeader>
                  <ModalBody>
                    The delivery charges is{" "}
                    {Number(this.state.delivery_value).toFixed(2)} for distance
                    of {this.state.delivery_description} approx. Please press
                    'Ok' to update the Order or click 'Cancel'
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={async () => {
                        await this.placeDelivery();
                      }}
                    >
                      Ok
                    </Button>{" "}
                    <Button color="secondary" onClick={this.calcDeliveryModal}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default OrderEdit;
