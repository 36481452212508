import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Form,
  Button,
  Alert,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Axios from "axios";
import keymarvel from "../../helpers/keymarvel";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

class OrderCreateBulk extends Component {
  constructor() {
    super();
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      errorMsg: "",
      order_info: "",
      s3FileName: "",
      driverKey: "",
      driverId: "",
      userRole: "",
      selectedFiles: [],
    };
    this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
    this.fileSize = 10000000;
  }

  handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
  };

  /**
   * Formats the size
   */
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  componentDidMount() {
    let driverKey = sessionStorage.getItem("secretKey");
    let driverId = sessionStorage.getItem("userId");
    var userRoleInSession = sessionStorage.getItem("userRole");
    this.setState({
      driverKey,
      driverId,
      userRole: userRoleInSession,
    });
    const { match, location, history } = this.props;
    console.log(location);
  }

  backToDispatch = () => {
    this.props.history.push("ecommerce-orders");
  };

  downloadBlob = (blob) => {
    const blobUrl = URL.createObjectURL(
      new Blob([blob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      })
    );
    window.open(blobUrl);
  };

  getFile = async () => {
    const res = await keymarvel.downloadSampleCSV();
    if (res.status === 200) {
      this.downloadBlob(res.data);
    } else {
      console.error("error");
    }
  };

  uploadFile = async () => {
    const formData = new FormData();
    formData.append("csvFile", this.state.selectedFiles[0]);
    const res = await keymarvel.uploadBulkDataCSV(formData);
    if (res.status === 200) {
      toastr.success("File Uploaded", "Success!");
      this.props.history.push("ecommerce-orders");
    } else {
      console.error("error");
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content" style={{ marginTop: "0px" }}>
          <Container fluid>
            {this.state.errorMsg ? (
              <Alert color="danger">
                {this.state.errorMsg === "No message available"
                  ? "Service failure, Please contact support team."
                  : this.state.errorMsg}
              </Alert>
            ) : (
              ""
            )}
            {this.state.order_info ? (
              <Alert color="success">{this.state.order_info}</Alert>
            ) : (
              ""
            )}

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <CardTitle>Excel sheet file upload</CardTitle>
                    <CardSubtitle className="mb-3">
                      <span>
                        We support only one file upload, download the sample
                        excel sheet format, fill with order information and
                        upload using the below form and click "Add bulk order"
                        button
                      </span>
                      <div className="text-sm-right">
                        <a
                          className={"btn btn-danger mr-4"}
                          onClick={() => this.getFile()}
                          role="button"
                          href="##"
                        >
                          <i className="bx bx-folder-plus "></i> Download sample
                          excel sheet
                        </a>
                      </div>
                    </CardSubtitle>
                    <Form>
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          this.handleAcceptedFiles(acceptedFiles)
                        }
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                              </div>
                              <h4>Upload files here or click to upload.</h4>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {this.state.selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <i className="bx bx-folder-plus "></i>
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md="6">
                <div className="text-sm-left">
                  <a
                    className={"btn btn-primary mr-4"}
                    onClick={() => this.backToDispatch()}
                    role="button"
                    href="#"
                  >
                    <i className="bx bx-task"></i> Back to dispatch
                  </a>
                </div>
              </Col>
              <Col>
                <div className="text-sm-right">
                  <a
                    className={"btn btn-success mr-4"}
                    onClick={() => this.uploadFile()}
                    role="button"
                  >
                    <i className="bx bx-upload"></i> Add bulk order
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default OrderCreateBulk;
