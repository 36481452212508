import React, { useEffect, useState, useCallback, Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import API from "../../config.json";
import Axios from "axios";

const Map = (props) => {
  const { sendSelectedOrders, highlightOrder, lookType, resetSelectedOrders, mapCenter } = props;
  const [dropOffMapCoordinates, setDropOffMapCoordinates] = useState([]);
  const [storeMapCoordinates, setStoreMapCoordinates] = useState([]);
  const [driverMapCoordinates, setDriverMapCoordinates] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBfXMcqxKYOgZAo9L1b7NHGvE_IQMWJ--Q",
  });
  const containerStyle1 = {
    width: "100%",
    height: "calc(45vh)",
  };

  const containerStyle2 = {
    width: "100%",
    height: "calc(90vh)",
  };

  const options = { closeBoxURL: "", enableEventPropagation: true };

  const addOrRemoveSelectedOrders = async (item) => {
    
      selectedOrders?.indexOf(item) === -1 ? selectedOrders.push(item) : selectedOrders.splice(selectedOrders?.indexOf(item), 1);
      setSelectedOrders(selectedOrders);
      
      sendSelectedOrders(selectedOrders);
  }

  const getData = async (driverKey, driverId) => {
    const response = await Axios.get(
      API.DELIVERY_API + "getTrackerInfo/" + driverId + "/" + driverKey,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).catch((error) => console.log(error.message));
    if (response) {
      setDriverMapCoordinates(response.data?.driverData);
      setStoreMapCoordinates(response.data?.storeData);
      setDropOffMapCoordinates(response.data?.orderData);
    }
  };

  useEffect(() => {
    let driverKey = sessionStorage.getItem("secretKey");
    let driverId = sessionStorage.getItem("userId");

    getData(driverKey, driverId);
    
    let refresh = setInterval(() => getData(driverKey, driverId), 10000);
    return () => clearInterval(refresh);
  }, []);

  useEffect(() => {
    let driverKey = sessionStorage.getItem("secretKey");
    let driverId = sessionStorage.getItem("userId");

    if (resetSelectedOrders) {
      setSelectedOrders([]);
    }

    getData(driverKey, driverId);
  }, [resetSelectedOrders]);

  const onLoad = useCallback(function onLoad(mapInstance) {
    // do something with map Instance
  });

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={lookType == 'version2' ? containerStyle2 : containerStyle1}
        center={mapCenter}
        zoom={12} clickableIcons={false}
        options={{ styles: [{ elementType: "labels", featureType: "poi", stylers: [{ visibility: "off", }], }], }}
      >
        {storeMapCoordinates.map((position, key) => {
          return (
            <Fragment key={"_map_" + key}>
              <InfoBox
                onLoad={onLoad}
                options={options}
                position={{
                  lat: position?.location[0],
                  lng: position?.location[1],
                }}
                zIndex={9}
              >
                <div
                  style={{
                    padding: 2,
                    width: '200px',
                    display: "flex",
                    overflowY: 'hidden',
                    overflowX: 'hidden'
                  }}
                >
                  {highlightOrder?.storeName?.includes(position.companyName) ? 
                      <div
                        style={{
                          color: "#ec0868",
                          overflowY: 'hidden',
                          overflowX: 'hidden'
                        }}
                      >
                        <i className="bx bx-star font-size-24 align-middle"></i>
                        <span style={{padding:0,
                              fontSize: 11, fontWeight: 'bold',
                              whiteSpace: "nowrap",
                              overflowY: 'hidden',
                              overflowX: 'hidden',
                              opacity: 0.8}}>{position?.companyName && position?.companyName.substring(0, 5)?.toUpperCase()}</span>
                      </div>
                    :
                    <div
                      style={{
                        margin: 0,
                        color: "#fd9d75",
                        overflowY: 'hidden',
                        overflowX: 'hidden'
                      }}
                    >
                      <i className="mdi mdi-cart-outline font-size-24 align-middle"></i>
                      <span style={{padding:0,
                              fontSize: 11, fontWeight: 'bold',
                              whiteSpace: "nowrap",
                              overflowY: 'hidden',
                              overflowX: 'hidden',
                              opacity: 0.5}}>{position?.companyName && position?.companyName.substring(0, 5)?.toUpperCase()}</span>
                    </div>
                  }
                </div>
              </InfoBox>
            </Fragment>
          );
        })}
        {driverMapCoordinates.map((position) => (
          <>
            {position && position.location &&
              <Fragment key={position?.id}>
                <InfoBox
                  onLoad={onLoad}
                  options={options}
                  position={{
                    lat: position?.location[0],
                    lng: position?.location[1],
                  }}
                  zIndex={11}
                >
                  <div
                    style={{
                      padding: 2,
                      width: '200px',
                      display: "flex",
                      overflowY: 'hidden',
                      overflowX: 'hidden'
                    }}
                  >
                    {position.activeOrders > 0 && (
                      <>
                        <div
                          style={{
                            color: "#F44336",
                            overflowY: 'hidden',
                            overflowX: 'hidden'
                          }}
                        >
                          <i className="mdi mdi-map-marker font-size-24 align-middle"></i>
                          <span style={{
                            padding:0,
                            fontSize: 11, fontWeight: 'bold',
                            whiteSpace: "nowrap",
                            overflowY: 'hidden',
                            overflowX: 'hidden',
                            opacity: 1
                          }} >{position.firstName.substring(0, 7)?.toUpperCase()} {position.lastName.substring(0, 1)?.toUpperCase()}</span>
                        </div>
                      </>
                    )}
                    {position.activeOrders <= 0 && (
                      <>
                        <div
                          style={{
                            padding: 0,
                            color: "#218380",
                            overflowY: 'hidden',
                            overflowX: 'hidden',
                          }}
                        >
                          <i className="mdi mdi-map-marker font-size-24 align-middle"></i>
                          <span style={{
                            padding:0,
                            fontSize: 11, fontWeight: 'bold',
                            whiteSpace: "nowrap",
                            overflowY: 'hidden',
                            overflowX: 'hidden',
                            opacity: 1
                          }} >{position.firstName.substring(0, 7)?.toUpperCase()} {position.lastName.substring(0, 1)?.toUpperCase()}</span>
                        </div>
                      </>
                    )}
                    
                  </div>
                </InfoBox>
              </Fragment>}</>
        ))}
        {dropOffMapCoordinates.map((position, key) => {
          return (
            <Fragment key={"_map_" + key}>
              <>
                <InfoBox
                  onLoad={onLoad}
                  options={options}
                  position={{
                    lat: position?.location[0],
                    lng: position?.location[1],
                  }}
                  zIndex={10}
                >
                  <div
                    style={{
                      padding: 2,
                      width:"125px",
                      display: "flex",
                      overflowY: 'hidden',
                      overflowX: 'hidden'
                    }}
                  >
                    
                    {highlightOrder?.id?.includes(position.id) ? 
                      <div
                        style={{
                          color: "#2d00f7",
                          overflowY: 'hidden',
                          overflowX: 'hidden'
                        }}
                      >
                        <i className="bx bx-star font-size-24 align-middle"></i>
                        <span style={{
                          padding:0,
                          fontSize: 11, fontWeight: 'bold',
                          whiteSpace: "nowrap",
                          overflowY: 'hidden',
                          overflowX: 'hidden',
                          opacity: 0.8
                        }}>{position.driverName ? position.driverName?.substring(0,7)?.toUpperCase() : position.storeName ? position.storeName?.substring(0,7)?.toUpperCase() : "NA"}</span>
                      </div>
                    :
                      <><div
                        style={{
                          color: "#0000ff",
                          overflowY: 'hidden',
                          overflowX: 'hidden'
                        }}
                      >
                        {selectedOrders.includes(position.id) ?
                          <Link
                              style={{ margin: 0, padding: 0 }}
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title={position.id}
                              to="##"
                              onClick={() => {
                                addOrRemoveSelectedOrders(position.id);
                              }}
                              className="text-body font-weight-bold font-size-16"
                            ><i style={{color: "#0000ff"}} className="mdi mdi-checkbox-marked font-size-24 align-middle"></i></Link>
                            : position.driverName && !position.driverName?.includes("UNASSIGNED") ? <i style={{color: "#0000ff"}} className="mdi mdi-home font-size-24 align-middle"></i> 
                            : <Link
                              style={{ margin: 0, padding: 0 }}
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title={position.id}
                              to="##"
                              onClick={() => {
                                addOrRemoveSelectedOrders(position.id);
                              }}
                              className="text-body font-weight-bold font-size-16"><i style={{color: "#0000ff"}} className="mdi mdi-checkbox-intermediate font-size-24 align-middle"></i></Link>
                        }
                        <span style={{
                          padding:0,
                          fontSize: 11, fontWeight: 'bold',
                          whiteSpace: "nowrap",
                          overflowY: 'hidden',
                          overflowX: 'hidden',
                          opacity: 0.5
                        }}>{position.driverName ? position.driverName?.substring(0,7)?.toUpperCase() : position.storeName ? position.storeName?.substring(0,7)?.toUpperCase() : "NA"}</span>
                      </div></>
                    }
                  </div>
                </InfoBox>
              </>
            </Fragment>
          );
        })}
      </GoogleMap>
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    renderMap()
  ) : (
    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
  );
};

export default Map;
